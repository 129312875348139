<template>
  <header
    class="flex items-center gap-2"
    :class="{
      'bg-card text-white': invert,
      'mb-4': !invert && !isPdfPage,
      'bg-card-secondary text-card-primary': !invert,
      'flex-row-reverse': header?.iconAlignment === 'right'
    }"
    :style="backgroundColor ? { backgroundColor } : {}"
  >
    <slot />
  </header>
</template>

<script lang="ts" setup>
import type { CardType } from '@autobid/ui/types/components/CardFamily'

interface Props {
  invert?: boolean
}

defineProps<Props>()

const isPdfPage = inject('isPdfPage', false)

const { header, backgroundColor } = inject('card') as CardType
</script>
