<template>
  <div
    class="flex items-center justify-center rounded-full p-1.5"
    :class="
      invert ? 'bg-white text-card-primary' : 'bg-card-primary text-white'
    "
    :style="header.iconColor ? { background: header.iconColor } : undefined"
  >
    <CommonIcon v-if="header.icon" :name="header.icon" class="h-5 w-5" />
  </div>
</template>

<script setup lang="ts">
import type { CardType } from '@autobid/ui/types/components/CardFamily'

interface Props {
  invert?: boolean
}

defineProps<Props>()

const { header } = inject('card') as CardType
</script>
