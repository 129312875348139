<template>
  <component
    :is="header.heading.name"
    :class="{
      'text-[1.3125rem]': !isPdfPage,
      'mt-1.5 text-lg': isPdfPage,
      '!text-white': !isCustomColor && invert,
      '!text-card-primary': !isCustomColor && !invert
    }"
    class="font-semibold"
    :style="
      isCustomColor ? { color: header.titleColor ?? textColor } : undefined
    "
  >
    {{ header.title }}</component
  >
</template>

<script setup lang="ts">
import type { CardType } from '@autobid/ui/types/components/CardFamily'

interface Props {
  invert?: boolean
}

defineProps<Props>()

const isPdfPage = inject('isPdfPage', false)

const { header, textColor } = inject('card') as CardType

const isCustomColor = header.titleColor || textColor
</script>
